import * as React from "react";

import { SEO, Layout, Link } from "../components";

const NotFoundPage = () => {
  return (
    <Layout>
      <SEO
        title="Página no encontrada"
        description="Lo sentimos, pero no pudimos encontrar la página que buscaba."
      />
      <div className="bg-white min-h-screen px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
        <div className="max-w-max mx-auto">
          <main className="sm:flex">
            <p className="text-4xl font-bold text-yellow-500 sm:text-5xl">
              404
            </p>
            <div className="sm:ml-6">
              <div className="sm:border-l sm:border-gray-200 sm:pl-6">
                <h1 className="text-4xl font-bold text-gray-900 tracking-tight sm:text-5xl">
                  Página no encontrada
                </h1>
                <p className="mt-4 text-base text-gray-500">
                  Lo sentimos, pero no pudimos encontrar la página que buscaba.
                </p>
                <p className="mt-4 text-base text-gray-500">
                  Puedes <Link to="/"> volver a nuestra página principal</Link>o{" "}
                  <Link to="/contacto">enviarnos un mensaje</Link> si no puedes
                  encontrar lo que está buscando.
                </p>
              </div>
            </div>
          </main>
        </div>
      </div>
    </Layout>
  );
};

export default NotFoundPage;
